import { NestedTopicFragment } from "../../../graphql/types"
import { BreadcrumbResource } from "../../components/breadcrumbs/breadcrumbs"
import { PathPrefix } from "../constants"

export const mapNestedTopics = (
  node?: NestedTopicFragment | null,
  list?: BreadcrumbResource[]
): BreadcrumbResource[] => {
  if (!node) {
    return []
  }

  if (!list) {
    list = []
  }

  const topic: BreadcrumbResource = {
    label: node.name!,
    link: `${PathPrefix.Topics}/${node.slug}`
  }

  if (node.contentfulparent) {
    list = mapNestedTopics(node.contentfulparent, list)
  }

  return [...list, topic]
}
