import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { Page } from "../../../components/page/page"
import { PageProps } from "../../../types/page"
import { mapPodcastShowIndexQueryToView } from "../mappers/podcast-show-index-mappers"
import { PodcastShowIndexHeader } from "../components/podcast-show-index-header/podcast-show-index-header"
import { PodcastShowIndexQuery } from "../podcast-types"
import {
  alphabeticalSearchIndexName,
  newestSearchIndexName,
  oldestSearchIndexName
} from "@features/search/search-constants"
import {
  SearchConfig,
  SearchFilters,
  SearchIndexes
} from "@features/search/search-types"
import { SearchResults } from "@features/search/components/search-results/search-results"

export const PodcastShowIndexTemplate: FunctionComponent<
  PageProps<PodcastShowIndexQuery>
> = ({ data, ...pageProps }) => {
  const { metadata, ...viewProps } = mapPodcastShowIndexQueryToView(data!)
  const filters: SearchFilters = [
    {
      attribute: "fields.primaryTopic.fields.name",
      title: "search-filter-topics"
    },
    {
      attribute: "fields.teachers.fields.shortName",
      title: "search-filter-teachers"
    }
  ]

  const config: SearchConfig = {
    filters: `type:"podcastEpisode" AND fields.podcastShow.fields.slug:${viewProps.show.slug}`
  }

  const allowedIndexes: SearchIndexes = [
    {
      id: "newest",
      label: "search-sort-newest",
      value: newestSearchIndexName
    },
    {
      id: "oldest",
      label: "search-sort-oldest",
      value: oldestSearchIndexName
    },
    {
      id: "alphabetical",
      label: "search-sort-alphabetical",
      value: alphabeticalSearchIndexName
    }
  ]

  return (
    <Page
      {...pageProps}
      pageOptions={{
        defaultSearchIndex: "newest",
        allowedIndexes
      }}
    >
      <PodcastShowIndexHeader {...viewProps} />
      <SearchResults
        filters={filters}
        config={config}
        indexes={allowedIndexes}
        variant="podcast-episodes-list"
        defaultStyleSheet={false}
        category={"podcastEpisode"}
        isWide={false}
      />
    </Page>
  )
}

export default PodcastShowIndexTemplate

export const query = graphql`
  query PodcastShowIndex($showId: String) {
    contentfulPodcastShow(id: { eq: $showId }) {
      ...PodcastShowView
    }
  }
`
