import { IntlShape } from "react-intl"
import { mapMetadata } from "../../../core/mappers/map-metadata"
import { ContentfulPodcastShow } from "../../../types/graphql"
import {
  PodcastShow,
  PodcastShowView,
  PodcastShowQuery,
  PodcastShowListenLinks,
  PodcastShowSocialLinks,
  PodcastTeacher
} from "../podcast-types"
import { PageContext } from "./../../../types/page"
import {
  mapPodcastEpisode,
  mapPodcastEpisodes
} from "./podcast-episode-mappers"
import { mapPodcastTeachers } from "./podcast-teacher-mappers"
import { PathPrefix } from "@core/constants"
import { formatAuthorNames } from "@utils/author-names"

export const mapPodcastShowListenLinks = (
  show: ContentfulPodcastShow | null
): PodcastShowListenLinks => ({
  // do not create a key if there is no url
  ...(show?.applePodcastsUrl && { applePodcastsUrl: show?.applePodcastsUrl }),
  ...(show?.googlePodcastsUrl && {
    googlePodcastsUrl: show?.googlePodcastsUrl
  }),
  ...(show?.iHeartRadioUrl && { iHeartRadioUrl: show?.iHeartRadioUrl }),
  ...(show?.deezerUrl && { deezerUrl: show?.deezerUrl }),
  ...(show?.pandoraUrl && { pandoraUrl: show?.pandoraUrl }),
  ...(show?.spotifyUrl && { spotifyUrl: show?.spotifyUrl }),
  ...(show?.stitcherUrl && { stitcherUrl: show?.stitcherUrl }),
  ...(show?.tuneInUrl && { tuneInUrl: show?.tuneInUrl }),
  ...(show?.rssUrl && { rssUrl: show?.rssUrl })
})

export const mapPodcastShowSocialLinks = (
  show: ContentfulPodcastShow | null
): PodcastShowSocialLinks => ({
  // do not create keys if there is no url.
  ...(show?.facebookUrl && { facebookUrl: show?.facebookUrl }),
  ...(show?.instagramUrl && { instagramUrl: show?.instagramUrl }),
  ...(show?.twitterUrl && { twitterUrl: show?.twitterUrl })
})

export const mapPodcastShow = (
  show: ContentfulPodcastShow | null
): PodcastShow => ({
  id: show?.contentful_id || "",
  slug: show?.slug || "",
  title: show?.title || "Missing Title",
  topic: show?.primaryTopic?.name || "",
  description: show?.description?.childMarkdownRemark?.rawMarkdownBody,
  color: show?.color,
  image: show?.image?.fixed?.src,
  hosts: mapPodcastTeachers(show?.hosts),
  listenLinks: mapPodcastShowListenLinks(show),
  socialLinks: mapPodcastShowSocialLinks(show)
})

type EpisodeTeacherMapper = (arg: {
  teachers: PodcastTeacher[] | undefined
}) => { name: string | null | undefined; link: string }

export const getEpisodeTeachers: EpisodeTeacherMapper = ({ teachers }) => {
  const singleTeacher = teachers?.length === 1
  const firstTeacher = teachers?.[0]

  const link = singleTeacher
    ? `${PathPrefix.Teachers}/${firstTeacher?.slug}`
    : PathPrefix.Teachers

  const name = formatAuthorNames(teachers?.map((t) => t.name)) || undefined

  return {
    name,
    link
  }
}

export const mapPodcastShowQueryToView = (
  data: PodcastShowQuery,
  pageContext: PageContext
): PodcastShowView => {
  const podcastShow = data.contentfulPodcastShow
  const latestEpisode = data.contentfulPodcastEpisode
  const recentEpisodes = data.allContentfulPodcastEpisode?.nodes
  const description = podcastShow?.description.childMarkdownRemark

  return {
    show: mapPodcastShow(podcastShow),
    latestEpisode: mapPodcastEpisode(podcastShow, latestEpisode),
    recentEpisodes: mapPodcastEpisodes(podcastShow, recentEpisodes),
    showViewMore: pageContext.hasMoreEpisodes || false,
    metadata: mapMetadata(
      podcastShow?.metadata,
      {
        title: podcastShow?.title,
        description: description?.plainText,
        image: podcastShow?.image.fixed?.src
      },
      {
        title: podcastShow?.pageTitle
      }
    )
  }
}
