import { ContentfulTeacher } from "../../../types/graphql"
import { PodcastTeacher } from "../podcast-types"
import { PathPrefix } from "@core/constants"

export const mapPodcastTeacher = (
  teacher?: ContentfulTeacher
): PodcastTeacher => ({
  slug: teacher?.slug || "",
  name: teacher?.shortName || "Missing Name",
  bio: teacher?.shortBio?.childMarkdownRemark?.rawMarkdownBody || "",
  image: teacher?.image?.fixed?.src || "",
  link: `${PathPrefix.Teachers}/${teacher?.slug}`
})

export const mapPodcastTeachers = (
  teachers?: ContentfulTeacher[]
): PodcastTeacher[] => (teachers ? teachers.map(mapPodcastTeacher) : [])
