import React, { Fragment, FunctionComponent } from "react"
import styles from "./podcast-show-index-header.module.scss"
import { Markdown } from "@components/markdown/markdown"
import { PodcastShow } from "@features/podcasts/podcast-types"

export interface PodcastShowIndexHeaderProps {
  show: PodcastShow
}

export const PodcastShowIndexHeader: FunctionComponent<
  PodcastShowIndexHeaderProps
> = ({ show }) => {
  const imageBackground = show.color
    ? { backgroundColor: show.color }
    : undefined

  return (
    <header className={styles.header}>
      <aside className={styles.showDetails}>
        <div className={styles.showMeta}>
          <h2 className={styles.title}>{`${show.title} Episodes`}</h2>
          <Markdown className={styles.body} content={show.description || ""} />
        </div>
      </aside>
      <div className={styles.showArtwork} style={imageBackground}>
        <div className={styles.showImageWrapper}>
          <img className={styles.showImage} src={show.image} alt={show.title} />
        </div>
      </div>
    </header>
  )
}
