import { mapNestedTopics } from "../../../core/mappers/map-nested-topics"
import { mapMetadata } from "../../../core/mappers/map-metadata"
import { ContentfulPodcastEpisode } from "../../../types/graphql"
import {
  mapScriptureReference,
  ScriptureReference,
  formatScriptureReference
} from "../../scriptures/mappers/scripture-reference-mapper"
import {
  PodcastEpisode,
  PodcastEpisodeView,
  PodcastEpisodeQuery
} from "../podcast-types"
import { PageContext } from "./../../../types/page"
import { identity } from "./../../../core/utils"
import { ContentfulPodcastShow } from "./../../../types/graphql/contentful/podcast-show"
import { mapPodcastShow } from "./podcast-show-mappers"
import { mapPodcastTeachers } from "./podcast-teacher-mappers"

export const sortPodcastEpisodes = (
  a: PodcastEpisode,
  b: PodcastEpisode
): number => {
  if (a.date > b.date) return -1
  if (a.date < b.date) return 1

  return 0
}

export const mapPodcastScriptures = (
  episode: ContentfulPodcastEpisode | null
): ScriptureReference[] => {
  const scriptures = []

  if (
    episode?.primaryScriptureReference &&
    episode.primaryScriptureReference.start.book
  ) {
    scriptures.push(episode.primaryScriptureReference)
  }

  if (episode?.secondaryScriptureReferences) {
    scriptures.push(...episode.secondaryScriptureReferences)
  }

  return scriptures.map(mapScriptureReference)
}

export const mapPodcastEpisodeMeta = (
  episode: ContentfulPodcastEpisode,
  showSlug: string | undefined
): string => {
  const defaultMeta = episode?.primaryTopic?.name
  const primaryScriptureReference = mapPodcastScriptures(episode)[0]

  // Based on the slug for the parent show, we want to display different meta types
  switch (showSlug) {
    case "askligonier":
      return mapPodcastTeachers(episode?.teachers)[0].name || defaultMeta
    case "westminster-shorter-catechism":
      return primaryScriptureReference
        ? formatScriptureReference(primaryScriptureReference, identity)
        : defaultMeta
    default:
      return defaultMeta
  }
}

export const mapPodcastEpisode = (
  show: ContentfulPodcastShow | null,
  episode: ContentfulPodcastEpisode | null
): PodcastEpisode => ({
  id: episode?.contentful_id || "",
  slug: episode?.slug || "",
  link: `/podcasts/${show?.slug}/${episode?.slug}`,
  date: new Date(episode?.date || ""),
  formattedDate: episode?.date || "",
  title: episode?.title || "Missing Title",
  description: episode?.description?.childMarkdownRemark.rawMarkdownBody,
  breadcrumbs: mapNestedTopics(episode?.primaryTopic),
  teachers: mapPodcastTeachers(episode?.teachers),
  scriptures: mapPodcastScriptures(episode),
  audioUrl: episode?.mediaUrl || "",
  topic: episode?.primaryTopic?.name || "",
  image: episode?.image?.fixed?.src,
  transcript: episode?.transcript?.childMarkdownRemark?.rawMarkdownBody || "",
  meta: episode ? mapPodcastEpisodeMeta(episode, show?.slug) : "",
  formattedSeasonAndEpisode:
    (episode?.season
      ? `Season ${episode.season}${episode.episode ? ", " : ""}`
      : "") + (episode?.episode ? "Episode " + episode?.episode : "")
})

export const mapPodcastEpisodes = (
  show?: ContentfulPodcastShow | null,
  episodes?: ContentfulPodcastEpisode[]
): PodcastEpisode[] =>
  episodes && show
    ? episodes
        .map((episode) => mapPodcastEpisode(show, episode))
        .sort(sortPodcastEpisodes)
    : []

export const mapPodcastEpisodeQueryToView = (
  data: PodcastEpisodeQuery,
  pageContext: PageContext
): PodcastEpisodeView => {
  const podcastShow = data.contentfulPodcastShow
  const podcastEpisode = data.contentfulPodcastEpisode
  const recentEpisodes = data.allContentfulPodcastEpisode.nodes

  const image = podcastEpisode?.image || podcastShow?.metadata?.image
  const description = podcastEpisode?.description?.childMarkdownRemark

  const show = mapPodcastShow(podcastShow)
  const episode = mapPodcastEpisode(podcastShow, podcastEpisode)

  episode.image = episode.image || show.image

  return {
    show,
    episode,
    recentEpisodes: mapPodcastEpisodes(podcastShow, recentEpisodes),
    showViewMore: pageContext.hasMoreEpisodes || false,
    metadata: mapMetadata(
      podcastEpisode?.metadata,
      {
        title: podcastEpisode?.title,
        description: description?.plainText,
        image: image?.fixed?.src
      },
      {
        title: podcastEpisode?.pageTitle
      }
    )
  }
}
