import { mapMetadata } from "../../../core/mappers/map-metadata"
import { PodcastShowIndexQuery } from "../podcast-types"
import { mapPodcastShow } from "./podcast-show-mappers"

export const mapPodcastShowIndexQueryToView = (data: PodcastShowIndexQuery) => {
  const podcastShow = data.contentfulPodcastShow
  const description = podcastShow?.description.childMarkdownRemark

  return {
    show: mapPodcastShow(podcastShow),
    metadata: mapMetadata(
      podcastShow?.metadata,
      {
        title: podcastShow?.title,
        description: description?.plainText,
        image: podcastShow?.image.fixed?.src
      },
      {
        title: podcastShow?.pageTitle
      }
    )
  }
}
